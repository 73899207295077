import { Field, Formik } from 'formik';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import { v4 } from 'uuid';
import Cookies from '../components/Cookies';
import Information from '../components/Information';
import Layout from '../components/Layout';
import ButtonGeneral from '../components/UI/ButtonGeneral';
import PageHeaderMax from '../components/UI/PageHeaderMax';
import { fingerprint } from '../helpers/fingerprint';
import ValidationSchema from '../helpers/validationSchema';
import { breakpointLarge } from '../styles/breakpoints';

const Form = styled.form`
  max-width: 30rem;
  margin: 0 auto;
  input,
  textarea {
    font-size: 1.2rem;
    display: block;
    width: 100%;
    border-radius: 1.6rem;
    border: 0;
    padding: 1rem;
    margin: 0.25rem 0 0.8rem;
    outline: 0;
    transition: border 0.25s ease;
    background-color:${props => props.theme.gray200};
    &:focus {
      border: 1px solid ${props => props.theme.blue};
    }
  }
`;
const ButtonContainer = styled.div`
  
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  button {
    text-align:start;
    font-size:2rem;
    border: none;
    width:100%;
    
  }
  @media (min-width: ${breakpointLarge}) {
    justify-content: start;
    button {
      width:280px;
    }
  }
`;
const MessageContainer = styled.div`
  font-size: 13px;
  color: ${props => props.theme.red};
`;

  const ContentBody = styled.div`
  display:flex;
  flex-direction:column;
  aling-items:center;
  justify-content:center;
  @media (min-width: ${breakpointLarge}) {
    flex-direction:row;
  }
  `
  const SectionOverlap = styled.div`
  padding:1rem;
  background-color:white;
  margin: -5.5rem 1rem 0 1rem;
  z-index: 98;
  position: relative;
  border-radius:2.5rem;
  @media (min-width: ${breakpointLarge}) {
    padding:2rem;
    max-width: 600px;
    margin: -5.8rem 2rem 0 0;
  }`
  const FullName= styled.div`
  
  @media (min-width: ${breakpointLarge}) {
    display:flex;
    gap:1rem;
  }
  `
  const Icon = styled.i`
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: ' ';
    border-radius: 0.07rem;
    position: absolute;
    width: 0.13rem;
    height: 0.9rem;
    background-color: white;
    transform: rotate(-45deg);
    transition: transform 0.25s ease, width 0.25s ease-out;
    top: 0.39rem;
    left: 0.98rem;

  }
  &:after {
    content: ' ';
    border-radius: 0.07rem;
    position: absolute;
    width: 0.13rem;
    height: 0.9rem;
    background-color: white;
    transform: rotate(45deg);
    top: 0.94rem;
    left: 0.98rem;
    transition: all 0.25s ease;
  }
`;
const InputContainer = styled.div`
 position: relative;
 display:flex;
 flex-direction:column;

`
const Tyc = styled.a`
  margin-left: 4px;
  text-decoration: underline;
  color: ${(props) => props.theme.blueDark};
  &,
  &:link,
  &:visited {
    color: ${(props) => props.theme.blueDark};
  }
`;

const Ptd = styled(Link)`
  display: block;
  text-align: left;
  text-decoration: underline;
  color: ${(props) => props.theme.blueDark};
  &,
  &:link,
  &:visited {
    color: ${(props) => props.theme.blueDark};
  }
`;
const ContentTerms =styled.div`
margin-left:20px;
`
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:start;
  margin-top: 10px; 

`;

const Onsubmit = (values, { setSubmitting }) => {
  fetch(`${process.env.GATSBY_JTP_APP}/api/v1/users/contact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Canvas': fingerprint.canvas,
      'X-Webgl': fingerprint.webgl,
      'X-Uuid': v4(),
      'X-Terminal': fingerprint.hash,
      Referer: document.referrer,
    },
    body: JSON.stringify(values),
  })
    .then(() => {
      setSubmitting(false);
      swal('', '¡Su envío fue exitoso!', 'success', {
        dangerMode: true,
      }).then(value => {
        window.location.reload();
      });
    })
    .catch(error => {
      setSubmitting(false);
      swal(
        '',
        'Hubo un error enviando su mensaje, por favor vuelva intentarlo.',
        'error',
        {
          dangerMode: true,
        }
      ).then(value => {
        window.location.reload();
      });
    });
};

export const ContactoPageTemplate = ({
  image,
  imageMobile,
  imagePosition,
  title,
  description,
}) => {
  useEffect(() => {
    fingerprint.compute();
  }, []);

  return (
    <Formik
      initialValues={{ names: '', lastNames: '', email: '', subject: '' }}
      validationSchema={ValidationSchema}
      onSubmit={Onsubmit}
      render={({
        touched,
        errors,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => (
        <>
          <Cookies/>
          <PageHeaderMax
            image={image}
            imageMobile={imageMobile}
            backgroundPosition={imagePosition}
            title={title}
            description={description}
          />
      <ContentBody>
          <SectionOverlap>
            <Form
              className="form"
              name="contact"
              onSubmit={handleSubmit}
              onReset={handleReset}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            ><FullName>
                <InputContainer className="field">
                  <Field className="input" type="text" name="names"  placeholder="Nombres"/>
                  <MessageContainer>
                    {touched.names && <p className="danger">{errors.names}</p>}{' '}
                  </MessageContainer>
                </InputContainer>
                <InputContainer  className="field">
                  
                  <Field className="input" type="text" name="lastNames"  placeholder="Apellidos"/>
                  <MessageContainer>
                    {touched.lastNames && (
                      <p className="danger">{errors.lastNames}</p>
                    )}
                  </MessageContainer>
                </InputContainer>
              </FullName>
              <InputContainer  className="field">
                
                <Field className="input" type="text" name="email"  placeholder="Email"/>
                <MessageContainer>
                  {touched.email && <p className="danger">{errors.email}</p>}
                </MessageContainer>
              </InputContainer>
              <InputContainer  className="field">
                <Field
                  className="input-textarea"
                  name="subject"
                  component="textarea"
                  rows="7"
                  placeholder="Mensaje"
                />
                <MessageContainer>
                  {touched.subject && (
                    <p className="danger">{errors.subject}</p>
                  )}
                </MessageContainer>
              </InputContainer>
              <div className="field">
                <CheckboxContainer>
                    <Field type="checkbox"  
                    name="acceptedTerms"
                    style={{ width: '20px', height: '20px', }}
                    /> 
                    <ContentTerms>
                      Acepto los
                      <Tyc
                        href="https://juanchotepresta.com/img/terminos_y_condiciones.pdf"
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Términos y condiciones"
                      > Términos y Condiciones</Tyc>{' '}
                        y
      
                        <Ptd to="/politicas-privacidad">
                          Politica de Tratamiento de Datos
                        </Ptd>
                    </ContentTerms>
                </CheckboxContainer>
                {touched.acceptedTerms && errors.acceptedTerms && (
                  <MessageContainer>
                    <p className="danger">{errors.acceptedTerms}</p>
                  </MessageContainer>
                )}
              </div>
              <ButtonContainer>
                <ButtonGeneral
                  disabled={isSubmitting}
                  as="button"
                  type="submit"
                >
                  Enviar
                  <Icon />
                </ButtonGeneral>
              </ButtonContainer>
            </Form>
          </SectionOverlap>
          <Information/>
          </ContentBody>
        </>
      )}
    />
  );
};

ContactoPageTemplate.propTypes = {
  image: PropTypes.object,
  imagePosition: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageMobile:PropTypes.object,
};

const ContactoPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ContactoPageTemplate
        image={post.frontmatter.image}
        imageMobile={post.frontmatter.imageMobile}
        imagePosition={post.frontmatter.imagePosition}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
    </Layout>
  );
};

ContactoPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactoPage;

export const contactoPageQuery = graphql`
  query ContactoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2018, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobile{
          childImageSharp {
            fluid(maxWidth: 2018, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imagePosition
        title
        description
      }
    }
  }
`;
